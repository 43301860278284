import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Accordion, Card } from 'react-bootstrap'

import { siteMetadata } from '../../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import Medemer from 'components/medemer/am'

import PMPortrait from '../../../content/images/pm-abiy-4.jpg'

class About extends React.Component {
  render() {
    const { location, data } = this.props

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="About" />

        <div>
          <section className="jumbotron text-left">
            <div className="container">
              <h1 className="jumbotron-heading">ስለ ጠቅላይ ሚኒስትር</h1>
              <p className="lead text-muted">ክብር ጠቅላይ ሚኒስትር አቢይ አህመድ (ፒኤችዲ)</p>
            </div>
          </section>

          <div className="container marketing">
            <div className="row featurette">
              <div className="col-md-7 order-md-2">
                <h2>የኢፌዴሪ ጠቅላይ ሚኒስትር</h2>
                <p>
                  ክቡር ዐቢይ አሕመድ አሊ (ዶ/ር) የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ አራተኛ ጠቅላይ
                  ሚኒስትር ናቸው፡፡ በሻሻ በተባለች በኦሮሚያ አጋሮ አቅራቢያ በምትገኝ ትንሽ ከተማ ነሐሴ 9 ቀን
                  1968 ዓ.ም ተወለዱ፡፡ በሙስሊም አባት እና በክርስቲያን እናት ያደጉት ዐቢይ አሕመድ በልዩነት
                  መሃል የመቻቻልንና የመግባባትን ባህል ከልጅነት እድሜያቸው ጀምሮ እየተማሩ አደጉ፡፡
                </p>
                <p>
                  መጋቢት 24 ቀን 2010 ዓ.ም ዐቢይ አሕመድ የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ ጠቅላይ
                  ሚኒስትር በመሆን ቃለ መሀላ ፈፀሙ፡፡ በኢትዮጵያ መንግስትና በኦሮሚያ ክልላዊ መንግስት ልዩ ልዩ
                  የሥራ ኃላፊነቶች ላይ ሲያገለግሉ የቆዩት ጠቅላይ ሚኒስትር ዐቢይ አሕመድ ወደ ከፍተኛው ስልጣን
                  ከመጡበት እለት ጀምሮ በንግግሮቻቸውና ተግባሮቻቸው ሁሉ ብሔራዊ አንድነት፤ ሰላማዊ ትስስር፤ የጋራ
                  ዕድገት እና ክልላዊ ውህደት የአስተዳደራቸው ማዕከላዊ ጭብጥ እንደሆነ በማስረገጥ የተከፋፈለውን
                  ማህበረሰብ ወደ አንድ ማምጣትን በዋናነት ያንፀባርቃሉ፡፡ በህዝብ ተወካዮች ምክር ቤት የመጀመሪያ
                  ንግግራቸውም ተስፋን በማለምለም በኢትዮጵያ ውስጥ የጋራ መፃኢ እድልን ለማሰብ መነሳሳትን ፈጠረ፡፡
                </p>
                <p>
                  በተለያዩ የመንግስት የኃላፊነት ቦታዎች ላይ ሰፋ ያለ ልምድ ያላቸው ጠቅላይ ሚኒስትሩ በሙያና
                  አስተዳደር ዘርፍም አይነተ ብዙ በመሪነት፡ በራዕይና በእውቀት ላይ የተመሰረተ አገልግሎት
                  አበርክተዋል፡፡ ገና በወጣትነት እድሜያቸው በደርግ ሥርዓት በኢትዮጵያውያን ላይ የሚደርሰውን ጭቆና
                  በመቃወም የትጥቅ ትግሉን ተቀላቀሉ፡፡ በኢትዮጵያ የብሄራዊ መከላከያ ሰራዊት ውስጥም የሌተናል
                  ኮኔልነት ማዕረግ ላይ ደርሰዋል፡፡ ከመንግስት የሥራ ሃላፊነቶቻቸው ውስጥም የቀድሞ የኦሮሞ ህዝቦች
                  ዴሞክራሲያዊ ድርጅት (ኦህዴድ) ጽ/ቤት ኃላፊ፤ የኦሮሚያ ክልላዊ መንግስት ምክትል ርዕሰ
                  መስተዳድር፤ የሳይንስና ቴክኖሎጂ ሚኒስቴር ሚኒስትር፤ የመረጃ ኔትወርክና ደህንነት ኤጀንሲ መስራች
                  ዳይሬክተር፤ የመንግስት የምርምር ኢንስቲትዩት የሳይንስና ቴክኖሎጂ መረጃ ማዕከል መስራችና ኃላፊ
                  በመሆን አገልግለዋል፡፡
                </p>
                <p>
                  ወደ ጠቅላይ ሚኒስትርነት ስልጣን ከመጡ ጀምሮ ሥርነቀል የፖለቲካና የኢኮኖሚ ሪፎርሞችን በማድረግ
                  ላይ ይገኛሉ፡፡ በድፍረትና በቆራጥነት ለለውጥ የተነሱት ጠቅላይ ሚኒስትር ዐቢይ የልዩነት ግድግዳን
                  በማፍረስ የዕርቅና የሰላም ድልድይን መገንባት የሪፎርም ጥረታቸው ዋነኛ ጭብጥ ነው፡፡ በእርሳቸው
                  አመራር ወቅትም በሺዎች የሚቆጠሩ እስረኞች ተፈትተዋል፤ በሰብአዊነት ላይ ወንጀል ፈፅመዋል የአገር
                  ሀብት ንብረት መዝብረዋል ተብለው የተጠረጠሩ የመንግስት ከፍተኛ አመራሮችና ግለሰቦች በሕግ ፊት
                  እንዲቀርቡ አድርገዋል፤ በቀደሙ ጊዜያት አሸባሪ በሚል ተፈርጀው የነበሩ ተፎካካሪ የፖለቲካ ፓርቲዎች
                  ጋርም ውይይትና ምክክር ተካሂዷል፤ ከጎረቤት ኤርትራ ጋር ታሪካዊ የሆነው የሰላም ስምምነት
                  ተጠናቅቋል፤ ለህግ እና ለፍትህ ዘርፍ ማሻሻያ የሚሆን አጀንዳ መተግበር ተጀምሯል፤ እንዲሁም
                  የመንግሥት የልማት ድርጅቶች በከፊል ወደግል ይዞታ ለማዘወር ውሳኔ ላይ ተደርሷል፡፡
                </p>
                <p>
                  ጠቅላይ ሚኒስትር ዐቢይ አሕመድ ሁለተኛ ዲግሪ በለውጥ አመራር (ትራንፎሬሜሽናል ሊደርሺፕ) እና
                  የቢዚነስ አስተዳደር፤ እንዲሁም የዶክትሬት ዲግሪያቸውን ከአዲስ አበባ ዩኒቨርስቲ በሰላምና ደህንነት
                  ጥናት አግኝተዋል፡፡ ጠቅላይ ሚኒስትር ዐቢይ አሕመድ ከባለቤታቸው ቀዳማዊት እመቤት ዝናሽ ታያቸው
                  ጋር ሶስት ሴቶችና በቅርቡ በጉዲፈቻ የወሰዱት አንድ ወንድ ልጅ አባት ናቸው፡፡
                </p>
                <p>
                  እ.ኤ.አ. ጥቅምት 11 ቀን 2019 ዶ / ር ዐቢይ አህመድ በኢትዮጵያ እና በኤርትራ መካከል የ
                  20 ዓመታትን የቆየውን ብጥብጥ ለማቆም ላደረጉት ሥራ የኖቤል የሰላም ሽልማት ተሸልመዋል ፡፡
                </p>
                <div>
                  <br />
                </div>
              </div>
              <div className="col-md-5 order-md-1">
                <img src={PMPortrait} width="100%" className="img-responsive" />
              </div>
            </div>
          </div>
        </div>
        <Medemer />
      </Layout>
    )
  }
}

export default About

export const query = graphql`
  query AmAboutPageQuery {
    profile: file(name: { eq: "profile" }) {
      childImageSharp {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    work1: file(name: { eq: "work1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    work2: file(name: { eq: "work2" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    work3: file(name: { eq: "work3" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    back1: file(name: { eq: "back1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    back2: file(name: { eq: "back2" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
  }
`
